<template>
  <div>
    <training-courses-tab-enrolments-add-new
      v-if="isManager"
      :is-add-new-user-sidebar-active.sync="isAddNewEnrolmentSidebarActive"
      :training-course-data="trainingCourseData"
      :enrolments-data="enrolments"
      @refetch-data="fetchEnrolments"
    />
    <b-card>
      <div>
        <div class="d-flex mb-2 justify-content-between align-items-center">
          <h3 class="mb-0">
            {{ trainingCourseData.name }}
          </h3>
          <div class="d-flex align-items-center">
            <b-button
              v-if="isManager && trainingCourseIsUpcoming(trainingCourseData)"
              variant="gradient-secondary"
              class="ml-2 d-none d-sm-block"
              :disabled="maxReached"
              @click="openEnrolmentSidebar"
            >
              Zapisz na szkolenie
            </b-button>
          </div>
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <p v-html="$options.filters.nl2br(trainingCourseData.description)" />
        <!--eslint-enable-->
        <hr>
        <b-row>
          <b-col
            cols="12"
            lg="3"
            sm="6"
            class="mb-1"
          >
            <h5>Dział</h5>
            <div>{{ resolveDepartment(trainingCourseData.department) }}</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            sm="6"
            class="mb-1"
          >
            <h5>Data rozpoczęcia</h5>
            <div>{{ trainingCourseData.date | date }}</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            sm="6"
            class="mb-1"
          >
            <h5>Godzina startu</h5>
            <div>{{ trainingCourseData.date | time }}</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            sm="6"
            class="mb-1"
          >
            <h5>Data zakończenia</h5>
            <div>{{ trainingCourseData.endDate | date }}</div>
          </b-col>
          <b-col
            v-if="!isTrainer"
            cols="12"
            lg="3"
            sm="6"
            class="mb-1"
          >
            <h5>Trener</h5>
            <div>
              {{ trainingCourseData.trainer.firstName }}
              {{ trainingCourseData.trainer.lastName }}<br><small><a :href="'mailto:' + trainingCourseData.trainer.email">{{
                trainingCourseData.trainer.email
              }}</a></small>
            </div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            sm="6"
            class="mb-1"
          >
            <h5>Lokalizacja</h5>
            <div>
              {{ trainingCourseData.localization }}
            </div>
          </b-col>
          <template v-if="isAdmin || isManager || isFordPoland">
            <b-col
              cols="12"
              lg="3"
              sm="6"
              class="mb-1"
            >
              <h5>Zajęte miejsca</h5>
              <div>
                <span
                  :class="maxReached ? 'text-warning font-weight-bold' : null"
                >{{ trainingCourseData.enrolmentsCount }}</span>&nbsp;/&nbsp;{{ trainingCourseData.numberOfParticipants }}
              </div>
            </b-col>
            <b-col
              cols="12"
              lg="3"
              sm="6"
              class="mb-1"
            >
              <h5>Koszt szkolenia</h5>
              <div>
                {{ trainingCourseData.priceInGrosze | priceInGrosze }}&nbsp;netto&nbsp;/&nbsp;os.
              </div>
            </b-col>
          </template>
          <b-col
            v-if="isAdmin || isFordPoland"
            cols="12"
            lg="3"
            sm="6"
            class="mb-1"
          >
            <h5>Status szkolenia</h5>
            <div>
              <b-badge
                pill
                :variant="`light-${resolveTrainingCourseStatus(trainingCourseData.status).variant}`"
                class="text-capitalize"
              >
                {{ resolveTrainingCourseStatus(trainingCourseData.status).text.toUpperCase() }}
              </b-badge>
            </div>
          </b-col>
        </b-row>
        <hr>
        <h3>Materiały informacyjne</h3>
        <hr>
        <training-courses-files
          v-if="trainingCourseData.informationMaterials.length"
          :existing-files="trainingCourseData.informationMaterials"
          class="mb-2"
        />
        <div
          v-else
          class="mb-2"
        >
          Brak materiałów informacyjnych do pobrania.
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BButton,
} from 'bootstrap-vue'
import resolveDepartment from '@/helpers/resolveDepartment'
import resolveTrainingCourseStatus from '@/helpers/resolveTrainingCourseStatus'
import trainingCourseIsUpcoming from '@/helpers/trainingCourseIsUpcoming'
import TrainingCoursesFiles from './TrainingCoursesFiles.vue'
import TrainingCoursesTabEnrolmentsAddNew from './TrainingCoursesTabEnrolmentsAddNew.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    TrainingCoursesFiles,
    TrainingCoursesTabEnrolmentsAddNew,
  },
  props: {
    trainingCourseData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isAddNewEnrolmentSidebarActive: false,
    }
  },
  computed: {
    maxReached() {
      return (
        this.trainingCourseData.enrolmentsCount
        >= this.trainingCourseData.numberOfParticipants
      )
    },
    enrolments() {
      const list = this.$store.state.trainingCourse.enrolments[
        this.trainingCourseData.id
      ]
        ? this.$store.state.trainingCourse.enrolments[
          this.trainingCourseData.id
        ]['hydra:member']
        : []
      return list
    },
  },
  beforeMount() {

  },
  methods: {
    resolveDepartment,
    resolveTrainingCourseStatus,
    trainingCourseIsUpcoming,
    fetchEnrolments() {
      return this.$store
        .dispatch(
          'trainingCourse/fetchEnrolmentsForTrainingCourse',
          this.$router.currentRoute.params.id,
        ).then(() => {
          // this.trainingCourseData.enrolmentsCount = this.enrolments.length
        })
    },

    fetchUsers() {
      return this.$store.dispatch('user/fetchList')
    },

    openEnrolmentSidebar() {
      this.fetchEnrolments()
      this.fetchUsers()
      this.isAddNewEnrolmentSidebarActive = true
    },
  },
}
</script>

<style>
</style>
