<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearSelection"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          Zapisz na szkolenie
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <div class="p-2">
        <form @submit.prevent="addFromSearch">
          <b-form-input
            v-model="searchQuery"
            autofocus
            placeholder="Szukaj..."
            class="mb-2"
          />
        </form>
        <transition name="fade">
          <b-alert
            :show="maxReached"
            variant="warning"
          ><div class="alert-body">
            Limit uczestników szkolenia został wyczerpany. Wybierz inny termin szkolenia.
          </div>
          </b-alert>
        </transition>

        <b-list-group>

          <b-list-group-item
            v-for="user in availableUsers"
            :key="user.id"
            button
            :active="userIsAdded(user)"
            :disabled="(!userIsAdded(user) && maxReached)"
            @click="toggleUser(user)"
          >
            <div class="d-flex align-items-center">
              <feather-icon
                :icon="userIsAdded(user) ? 'CheckSquareIcon' : 'SquareIcon'"
                class="mr-1"
              />
              <div class="d-flex w-100 justify-content-between">
                <div>
                  {{ user.lastName }} {{ user.firstName }}
                </div>
                <div class="text-nowrap d-flex align-items-center">
                  <feather-icon
                    :icon="resolveUserRoles(user.roles).icon"
                    size="12"
                    class="mr-25 ml-1"
                    :class="`text-${resolveUserRoles(user.roles)}`"
                  />
                  <span class="align-text-top text-capitalize font-small-2">{{
                    resolveUserRoles(user.roles).text
                  }}</span>
                </div>
              </div>
            </div>
          </b-list-group-item>
          <b-list-group-item
            v-if="!availableUsers.length"
            variant="warning"
          >
            Nie znaleziono dostępnych pracowników.
          </b-list-group-item>
        </b-list-group>

        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            :disabled="!selectedUsers.length || loading"
            @click="addEnrolments"
          >
            Zapisz
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Anuluj
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BButton,
  BFormInput,
  BListGroup,
  BListGroupItem, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import countries from '@/@fake-db/data/other/countries'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import resolveUserRoles from '@/helpers/resolveUserRoles'

export default {
  components: {
    BSidebar,
    BButton,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BAlert,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    trainingCourseData: {
      type: Object,
      required: true,
    },
    enrolmentsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedUsers: [],
      loading: false,
      searchQuery: null,
    }
  },

  computed: {
    usersList() {
      let list = this.$store.state.user.list ? this.$store.state.user.list['hydra:member'] : []

      if (this.searchQuery) {
        const explodedQuery = this.searchQuery.toLowerCase().split(' ')
        let found = false

        list = list.filter(item => {
          found = false
          Object.values(item).forEach(value => {
            explodedQuery.forEach(query => {
              if (value && value.toString().toLowerCase().indexOf(query) !== -1) found = true
            })
          })
          return found
        })
      }

      list = list.sort((a, b) => {
        const nA = a.lastName.toLowerCase()
        const nB = b.lastName.toLowerCase()

        if (nA < nB) return -1
        if (nA > nB) return 1
        return 0
      })

      return list
    },

    availableUsers() {
      return this.usersList.filter(user => (this.enrolmentsData.findIndex(enrolment => enrolment.user === user['@id']) === -1) && user.departments.includes(this.trainingCourseData.department))
    },

    maxReached() {
      return (
        (this.trainingCourseData.enrolmentsCount + this.selectedUsers.length) >= this.trainingCourseData.numberOfParticipants
      )
    },
  },

  beforeMount() {
    // already refreshed when parent loads
    // this.$store.dispatch('user/fetchList')
  },

  methods: {
    resolveUserRoles,
    assignDealerForManager() {
      // eslint-disable-next-line prefer-destructuring
      this.userData.dealer = this.dealerOptions[0]
    },

    clearSelection() {
      this.selectedUsers = []
    },

    addFromSearch() {
      if (!this.searchQuery) return

      this.availableUsers.forEach(user => {
        if (!this.userIsAdded(user) && !this.maxReached) this.selectedUsers.push(user)
      })

      this.searchQuery = null
    },

    userIsAdded(user) {
      return this.selectedUsers.indexOf(user) !== -1
    },

    toggleUser(user) {
      if (!this.userIsAdded(user)) this.selectedUsers.push(user)
      else this.selectedUsers.splice(this.selectedUsers.indexOf(user), 1)
    },

    addEnrolments() {
      this.loading = true
      const enrolmentStack = []

      function getError(error) {
        switch (error) {
          case 'trainingCourse: There are no free places in this training course.': return 'Nie ma dostępnych miejsc na to szkolenie'
          default: return error
        }
      }

      this.selectedUsers.forEach(user => {
        const request = new Promise((resolve, reject) => {
          this.$store.dispatch('trainingCourse/addEnrolment', {
            user: user['@id'],
            trainingCourse: this.trainingCourseData['@id'],
          }).then(response => {
            if (response && response.status === 201) {
              resolve(response)
            } else { reject(response) }
          }).catch(error => reject(error))
        })

        enrolmentStack.push(request)
      })

      Promise.all(enrolmentStack).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              this.selectedUsers.length === 1
                ? 'Zapisano pracownika na szkolenie'
                : `Zapisano ${this.selectedUsers.length} pracowników na szkolenie`,
            icon: 'UserPlusIcon',
            variant: 'success',
          },
        })
        this.$emit('refetch-data')
        this.$emit('update:is-add-new-user-sidebar-active', false)
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Nie udało się wysłać zaproszenia.',
            text: error ? getError(error.data['hydra:description']) : null,
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.loading = false
        this.trainingCourseData.enrolmentsCount += this.selectedUsers.length
      })
    },

  },
}
</script>
